import React from 'react'
import ScrollToTop from "react-scroll-to-top";
import { useGetData } from '../data_store/getData';
const Footer = () => {

  const { mainContent: dataStore,loading } = useGetData()

  return (!loading &&
    <>
      {/* <!-- ======= Footer ======= --> */}

      <footer id="footer">
        <div class="container">
          <div class="copyright">
            All Rights Reserved. Created & Hosted by <strong><span>Debabrata Paul</span></strong> {dataStore?.apiChangeVersion}
          </div>
          <div class="credits">
          </div>
        </div>
      </footer>
      {/* <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> */}
      <ScrollToTop smooth color='white' style={{ 'backgroundColor': "#149ddd", "borderRadius": "50px" }} />
    </>
  )
}

export default Footer
