import React from 'react'
// import dataStore from "../data_store/data.json"
import { useGetData } from '../data_store/getData'
import Projects from './Projects'

const Resume = () => {
    const { mainContent: dataStore, loading } = useGetData()

    function calculateExperience(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    return (!loading &&
        <main id="main">
            {/* <!-- ======= Resume Section ======= --> */}
            <section id="resume" class="resume">
                <div class="container">
                    <div class="section-title">
                        <h2>Resume</h2>
                    </div>
                    <div class="row">
                        <div class="col-lg-6" data-aos="fade-up">
                            {
                                dataStore.resume_summary_and_education.map(value => {
                                    return (<>
                                        <h3 class="resume-title">{value.heading}</h3>

                                        {
                                            value.subheading.map(value => {
                                                return (<div class="resume-item">
                                                    <h4>{<span dangerouslySetInnerHTML={{ __html: value.name }} />}</h4>
                                                    {value.date ? <h5>{value.date}</h5> : ""}
                                                    <p>
                                                        {value.info.includes("[experience_year_here]") ? <em>
                                                            {value.info.split("[experience_year_here]")[0] + calculateExperience("2021/06/28") + "+" + value.info.split("[experience_year_here]")[1]}
                                                        </em>
                                                            : <em>{value.info}</em>
                                                        }
                                                    </p>
                                                    <ul>
                                                        {value.subinfo.map(value => {
                                                            return (<li>{value}</li>)
                                                        })}

                                                    </ul>
                                                </div>)
                                            })
                                        }

                                    </>)
                                })
                            }

                            <h3 class="resume-title">{dataStore.resume_publication.heading}</h3>
                            {
                                dataStore.resume_publication.subheading.map(items => {
                                    return (<div class="resume-item">
                                        <p>
                                            <em>
                                                <b>
                                                    {items.title}

                                                </b>
                                            </em>
                                        </p>
                                        <h5>{items.publisher_details}</h5>
                                        <p>
                                            <em>
                                                <a href={items.publishing_url}
                                                    target="_blank">{items.publishing_url_caption}</a>
                                            </em>
                                        </p>
                                    </div>)
                                })
                            }
                        </div>
                        <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            <h3 class="resume-title">{dataStore.resume_professional_experience.heading}</h3>
                            {
                                dataStore.resume_professional_experience.subheading.map(value => {
                                    return (
                                        <div class="resume-item">
                                            <h4>{value.designaton}</h4>
                                            <h5>{value.date}</h5>
                                            {value.info.map(info => {
                                                return (<p><em>{info}</em></p>)
                                            })
                                            }
                                            {value.projects.map(projects => {
                                                return (
                                                    <>
                                                        <p>
                                                            <em><b>{projects.name}</b></em>
                                                        </p>
                                                        <ul>
                                                            {projects.activities.map(activities => {
                                                                return (<li>{<span dangerouslySetInnerHTML={{ __html: activities }} />}</li>)
                                                            })}
                                                        </ul>
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    )
                                })

                            }

                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Resume Section --> */}
        </main>
    )
}

export default Resume
