// import { current_details } from './about_me_text';
import aboutMeContent from "./about_me_text.json"
import data from "./data.json"
import image_data from "./image_data.json"
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';


export function useGetData() {
    const [loading, setLoading] = useState(true)
    //if api call fails, default data will be loaded
    const [aboutMe, setAboutMe] = useState(aboutMeContent)
    const [mainContent, setMainContent] = useState(data)
    const [imageData, setImageData] = useState(image_data)
    // useEffect(() => {
    //     fetch('https://debabrata-paul.cyclic.app/api/v1/getData')
    //         .then((response) => response.json())
    //         .then((details) => {
    //             setAboutMe(details.about_me_content)
    //             setMainContent(details.main_content)
    //             setImageData(details.image_data)
    //             setLoading(false)
    //             console.log(details.about_me_content)
    //             console.log(details.main_content)
    //             console.log(details.image_data)
    //         })
    // }, [])

    const apiUrl = process.env.REACT_APP_API_URL || 'https://debabrata-paul.cyclic.app/api/v1/getData'
    const queryFunc = () => fetch(apiUrl).then((response) => response.json())
    // .then((details) => {
    //     setAboutMe(details.about_me_content)
    //     setMainContent(details.main_content)
    //     setImageData(details.image_data)
    //     // setLoading(false)
    //     console.log(details.about_me_content)
    //     console.log(details.main_content)
    //     console.log(details.image_data)
    // })

    const result = useQuery('data', queryFunc)
    useEffect(() => {
        if (!result.isLoading || result.failureCount > 0 || result.status === 'error')
            setLoading(false)

        if (result.data) {
            setAboutMe(result.data.about_me_content)
            setMainContent(result.data.main_content)
            setImageData(result.data.image_data)
        }

    }, [result])


    return { aboutMe, mainContent, imageData, loading }
}