import React from "react";
// import dataStore from "../data_store/data.json"
import $ from "jquery";
import { useScrollspy } from "../utils/scrollHighlightSideBar"
import clsx from "clsx";
import { useGetData } from '../data_store/getData'

const Sidebar = () => {
    const { mainContent: dataStore, loading } = useGetData()
    // Shorthand for $( document ).ready()
    //closing sidebar onclick (for mobile devices)
    $(() => {
        $(".nav-link").off().on('click', () => {
            if ($("body").hasClass("mobile-nav-active")) {
                $("body").removeClass("mobile-nav-active")
                $("#open-side-bar").removeClass("bi-x")
                $("#open-side-bar").addClass("bi-list")
            }
        })
    })

    // const addActiveClassOnClick = (e) => {
    //     $(".nav-link").removeClass("active")
    //     console.log($(".nav-link"))
    //     $(e.target).closest("a").addClass("active")
    // }
    const sidebar_details = [{
        "id": "home",
        "name": "Home",
        "icon_class": "bx bx-home"
        // "icon_class": "bx bx-bookmark-alt-plus"
    },
    {
        "id": "about",
        "name": "About",
        "icon_class": "bx bx-user"
    },
    {
        "id": "skills",
        "name": "Skills",
        "icon_class": "bx bx-bookmark-alt-plus"
    },
    {
        "id": "resume",
        "name": "Resume",
        "icon_class": "bx bx-file-blank"
    },
    {
        "id": "projects",
        "name": "Projects",
        "icon_class": "bx bx-code-alt"
    },
    {
        "id": "activities_awards",
        "name": "Activities & Awards",
        "icon_class": "bx bx-award"
    },
    {
        "id": "certifications",
        "name": "Certifications",
        "icon_class": "bx bx-receipt"
    }, {
        "id": "achievements",
        "name": "Achievements",
        "icon_class": "bx bx-trophy"
    }]
    const ids = sidebar_details.map((data) => {
        return data.id

    })
    // console.log(ids)
    const activeId = useScrollspy(ids, 54);
    return (!loading &&
        <>

            {/* <!-- ======= Header ======= --> */}
            <header id="header">
                <div class="d-flex flex-column">

                    <div class="profile">
                        <img src="assets/img/profile-img.jpg" alt="" class="img-fluid" style={{ borderRadius: '19%' }} />
                        <h1 class="text-light"><a href="index.html">Debabrata Paul</a></h1>
                        <div class="social-links mt-3 text-center">
                            <a href={dataStore["social-media"].linkedin} target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                            <a href={dataStore["social-media"].twitter} target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
                            <a href={dataStore["social-media"].fb} target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                            <a href={dataStore["social-media"].insta} target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                            <a href={dataStore["social-media"].github} target="_blank" class="github"><i class="bx bxl-github"></i></a>
                            {/* <a href={dataStore["social-media"].whatsapp} target="_blank" class="telegram"><i class="bx bxl-whatsapp"></i></a> */}
                            {/* <!-- <a href="#" target="_blank" class="google-plus"><i class="bx bxl-skype"></i></a> --> */}
                        </div>
                    </div>

                    <nav id="navbar" class="nav-menu navbar">
                        <ul>
                            {sidebar_details.map((data) => (
                                <li key={`menu-item-${data.id}`}>
                                    <a
                                        href={`#${data.id}`}
                                        className={clsx(
                                            "nav-link",
                                            "scrollto",
                                            data.id === activeId && "active"
                                        )}
                                    >
                                        <i class={data.icon_class}></i>
                                        <span>{data.name}</span>
                                    </a>
                                </li>
                            ))}


                            {/* <li><a href="#hero" class="nav-link scrollto active" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-home"></i> <span>Home</span></a></li>
                            <li><a href="#about" class="nav-link scrollto" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-user"></i> <span>About</span></a></li>
                            <li><a href="#resume" class="nav-link scrollto" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-file-blank"></i> <span>Resume</span></a></li>
                            <li><a href="#projects" class="nav-link scrollto" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-code-alt"></i> <span>Projects</span></a></li>
                            <li><a href="#activities_awards" class="nav-link scrollto" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-award"></i> <span>Activities & Awards</span></a></li>
                            <li><a href="#certifications" class="nav-link scrollto" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-receipt"></i> <span>Certifications & Extra-Curricular</span></a></li>
                            <li><a href="#achievements" class="nav-link scrollto" onClick={(e) => addActiveClassOnClick(e)}><i class="bx bx-trophy"></i> <span>Achievements</span></a></li> */}

                            {/* <!-- <li><a href="#services" class="nav-link scrollto"><i class="bx bx-server"></i> <span>Services</span></a></li>
                            <li><a href="#contact" class="nav-link scrollto"><i class="bx bx-envelope"></i> <span>Contact</span></a></li> --> */}
                        </ul>
                        {/* </ScrollspyNav> */}
                    </nav> {/* <!-- .nav-menu --> */}
                </div>
            </header>
            {/* <!-- End Header --> */}
        </>
    )
}

export default Sidebar