import { useState, useLayoutEffect } from "react";
// Restrict value to be between the range [0, value]
const clamp = function (value) { return Math.max(0, value); };
// Check if number is between two values
const isBetween = function (value, floor, ceil) {
    return value >= floor && value <= ceil;
};

export const useScrollspy = function (ids, offset) {
    if (offset === void 0) { offset = 0; }
    const _a = useState(""), activeId = _a[0], setActiveId = _a[1];
    useLayoutEffect(function () {
        const listener = function () {
            const scroll = window.pageYOffset;
            const position = ids
                .map(function (id) {
                    const element = document.getElementById(id);
                    if (!element)
                        return { id: id, top: -1, bottom: -1 };
                    const rect = element.getBoundingClientRect();
                    const top = clamp(rect.top + scroll - offset);
                    const bottom = clamp(rect.bottom + scroll - offset);
                    return { id: id, top: top, bottom: bottom };
                })
                .find(function (_a) {
                    const top = _a.top, bottom = _a.bottom;
                    return isBetween(scroll, top, bottom);
                });
            setActiveId((position === null || position === void 0 ? void 0 : position.id) || "");
        };
        listener();
        window.addEventListener("resize", listener);
        window.addEventListener("scroll", listener);
        return function () {
            window.removeEventListener("resize", listener);
            window.removeEventListener("scroll", listener);
        };
    }, [ids, offset]);
    return activeId;
};
