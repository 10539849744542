import React from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
// import dataStore from "../data_store/data.json"
import { useGetData } from '../data_store/getData'

const Skills = () => {
  const { mainContent: dataStore, loading } = useGetData()
  return (!loading &&
    <main id="main">
      {/* <!-- ======= Skills Section ======= --> */}
      <section id="skills" class="skills section-bg">
        <div class="container">

          <div class="section-title">
            <h2>Skills</h2>
          </div>

          <div class="row skills-content">
            {dataStore.skills.map((value, index) => {

              return (<div class="col-lg-6" data-aos="fade-up">
                <div class="progress">
                  <span class="skill">{value.name} <i class="val">{value.value}%</i></span>
                  <div class="progress-bar-wrap">
                    {/* <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div> */}
                    <ProgressBar animated min={0} max={100} now={value.value} />
                  </div>
                </div>
              </div>)
            })}
          </div>
        </div>
      </section>
      {/* <!-- End Skills Section --> */}
    </main>
  )
}

export default Skills
