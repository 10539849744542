import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import About from './components/About'
import Skills from './components/Skills'
import Resume from './components/Resume'
import Projects from './components/Projects'
import ActivitiesAndAwards from './components/ActivitiesAndAwards'
import CertificationsAndExtraCurricular from './components/CertificationsAndExtraCurricular'
import Achievements from './components/Achievements'
import Footer from './components/Footer'
import { useGetData } from './data_store/getData'
import Loader from './utils/loader'

function App() {
  const { loading } = useGetData()

  return (loading ? <Loader /> :
    <Router>
      {/* <Header /> */}

      <Switch>
        <Route exact path='/'>
          <Sidebar />
          <Header />
          <About />
          <Skills />
          <Resume />
          <Projects />
          <ActivitiesAndAwards />
          <CertificationsAndExtraCurricular />
          <Achievements />
          <Footer />
        </Route>
        {/* <Route path='/gallery'>
          <Header />
          <ImageBanner />

        </Route> */}


        {/* Don't add any routes below ----> End */}

        {/* <Route path='*'>
          <Header />
          <Error />
        </Route> */}
      </Switch>

    </Router>
  )
}

export default App
