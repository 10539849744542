import React from 'react'
// import dataStore from "../data_store/data.json"
// import { about_me_text } from "../data_store/about_me_text"
// import aboutMe from '../data_store/about_me_text.json'
import { useGetData } from '../data_store/getData'

const About = () => {
    const { mainContent: dataStore, loading, aboutMe } = useGetData()

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    const replaceValues = (text, valueObject) => {
        // value object Structure = {"value to replace": "Actual value"}
        let temp = text
        Object.keys(valueObject).forEach(key => {
            // for replacing values with current_company_here
            temp = temp.includes(key) ?
                temp.split(key)[0] + valueObject[key] + temp.split(key)[1] : temp
        })
        return temp
    }
    return (!loading &&
        <main id="main">
            {/* <!-- ======= About Section ======= --> */}
            <section id="about" class="about">
                <div class="container">

                    <div class="section-title">
                        <h2>About</h2>
                        {
                            aboutMe.about_me_text.map(item => {

                                // for replacing values with current_company_here & current_position_here
                                let value = replaceValues(item, { "[current_company_here]": aboutMe.current_company, "[current_position_here]": aboutMe.current_position })

                                return <p dangerouslySetInnerHTML={{ __html: value }}></p>
                            })
                        }
                        {/* {<span dangerouslySetInnerHTML={{ __html: aboutMe.about_me_text }} />} */}
                    </div>

                    <div class="row">
                        <div class="col-lg-4" data-aos="fade-right">
                            <img src="assets/img/profile-img.jpg" class="img-fluid" alt="" />
                        </div>
                        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <h3>Software Engineer</h3>
                            <div class="row">
                                {Object.keys(dataStore.about).map((value, index) => {
                                    return (<div class="col-12">
                                        <ul>
                                            {value === "Website" && <li><i class="bi bi-chevron-right"></i> <strong>{value}:</strong> <span><a href={dataStore.about[value]} target="_blank">[Click Here]</a></span></li>}
                                            {value === "Age" && <li><i class="bi bi-chevron-right"></i> <strong>{value}:</strong> <span>{getAge(dataStore.about[value])} </span></li>}
                                            {value !== "Website" && value !== "Age" && <li><i class="bi bi-chevron-right"></i> <strong>{value}:</strong> <span>{dataStore.about[value]} </span></li>}
                                        </ul>
                                    </div>)

                                })}
                            </div>
                            <p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default About
