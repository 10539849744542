import React from 'react'
import Typer from '../utils/Typer'
import $ from "jquery";
// import { current_details } from '../data_store/about_me_text';
import { useGetData } from '../data_store/getData';

const Header = () => {
  const { aboutMe: current_details, loading } = useGetData()
  // Shorthand for $( document ).ready()
  $(() => {
    $("#open-side-bar").off().on('click', () => {
      if ($("body").hasClass("mobile-nav-active")) {
        $("body").removeClass("mobile-nav-active")
        $("#open-side-bar").removeClass("bi-x")
        $("#open-side-bar").addClass("bi-list")
      }
      else {
        $("body").addClass("mobile-nav-active")
        $("#open-side-bar").removeClass("bi-list")
        $("#open-side-bar").addClass("bi-x")
      }
    })
  })
  return (!loading &&
    <>
      {/* <!-- ======= Mobile nav toggle button ======= --> */}
      <i class="bi bi-list mobile-nav-toggle d-xl-none" id="open-side-bar" ></i>

      {/* <!-- ======= Home Section ======= --> */}
      <section id="home" class="d-flex flex-column justify-content-center align-items-center">
        <div class="home-container" data-aos="fade-in">
          <h1>Debabrata Paul</h1> <br />
        </div>
        <div class="home-container" data-aos="fade-in">
          <p>
            <Typer
              heading={'I\'m a '}
              dataText={["Software Engineer", "Developer"]}
            />
          </p>
        </div>
        <div class="home-container" data-aos="fade-in">
          <p style={{ "fontSize": "larger", "color": "#8DFF0A", "fontWeight": "bolder", "maxWidth": "500px", "wordBreak": "breakAll", "whiteSpace": "breakSpaces !important", "padding": "15px", "borderBottom": "#ddbe14" }}><i>
            <Typer
              heading={''}
              dataText={[`Currently working in ${current_details.current_company} as a ${current_details.current_position}.`]}
            /></i>
          </p>
        </div>
      </section >
      {/* <!-- End Home --> */}
    </>
  )
}

export default Header
