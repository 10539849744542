import React, { useEffect, useState } from 'react'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
// import imageStore from "../data_store/image_data.json"
import { useGetData } from '../data_store/getData';

// const fadeImages = [
//     {
//         url: 'https://drive.google.com/uc?export=view&id=1HG2SI9IylrnQUhAH9rJKFOHDLQ-13yjr',
//         // caption: 'First Slide'
//     },
//     // {
//     //     url: 'assets/img/achievements/2.jpg',
//     //     // caption: 'Second Slide'
//     // },
//     // {
//     //     url: 'assets/img/achievements/3.jpg',
//     //     // caption: 'Third Slide'
//     // },
//     // {
//     //     url: 'assets/img/achievements/6.jpg',
//     //     // caption: 'Third Slide'
//     // }
// ];

const fadeImages_portrait = [

    {
        url: 'assets/img/achievements/4.jpg',
        // caption: 'Third Slide'
    },
    {
        url: 'assets/img/achievements/5.jpg',
        // caption: 'Third Slide'
    },
    {
        url: 'assets/img/achievements/7.jpg',
        // caption: 'Third Slide'
    }
];

const zoomOutProperties = {
    duration: 3500,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.1,
    arrows: true
};

export default function Achievements() {
    const { imageData: imageStore, loading } = useGetData()
    const [fadeImages, setFadeImages] = useState([])

    const imageCreator = (root_url, image_json) => {
        let image_store = []
        image_json.map(value => {
            image_store.push({ url: root_url + value.id, caption: value.caption })
        })
        return image_store
    }
    useEffect(() => {
        // fadeImages = imageCreator(imageStore.root_url, imageStore.academics)
        if (!loading)
            setFadeImages(imageCreator(imageStore.root_url, imageStore.academics))
    }, [loading])

    return (!loading &&
        <main id="main">
            {/* <!-- ======= Portfolio Section ======= --> */}
            <section id="achievements" class="portfolio section-bg">
                <div class="container">
                    <div class="section-title">
                        <h2>Achievements</h2>
                    </div>

                    <div class="row" data-aos="fade-up">
                        <div class="col-lg-12 d-flex justify-content-center">
                            <ul id="portfolio-flters">
                                <li data-filter="*" class="filter-active">All</li>
                                {/* <li data-filter=".filter-app">App</li>
                                <li data-filter=".filter-card">Card</li>
                                <li data-filter=".filter-web">Web</li> */}
                            </ul>
                        </div>
                    </div>

                    <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
                        {/* <div class="col-lg-6 col-md-6 portfolio-item filter-app">
                            <div className="slide-container">
                                <Fade {...zoomOutProperties}>
                                    {fadeImages.map((fadeImage, index) => (
                                        <div className="each-fade" key={index}>
                                            <div className="image-container ">
                                                <img src={fadeImage.url} />
                                            </div>
                                            <h2>{fadeImage.caption}</h2>
                                        </div>
                                    ))}
                                </Fade>
                            </div>
                        </div> */}


                        {/* <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                            <div className="slide-container">
                                <Fade {...zoomOutProperties}>
                                    {fadeImages_portrait.map((fadeImage, index) => (
                                        <div className="each-fade" key={index}>
                                            <div className="image-container ">
                                                <img src={fadeImage.url} />
                                            </div>
                                            <h2>{fadeImage.caption}</h2>
                                        </div>
                                    ))}
                                </Fade>
                            </div>
                        </div> */}

                        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                            <Fade {...zoomOutProperties}>
                                {fadeImages.map((fadeImage, index) => (
                                    <div className="each-fade" key={index}>
                                        <div className="image-container ">
                                            <img src={fadeImage.url} />
                                        </div>
                                        <h2>{fadeImage.caption}</h2>
                                    </div>
                                ))}
                            </Fade>
                        </div>

                        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                            <Fade {...zoomOutProperties}>
                                {fadeImages_portrait.map((fadeImage, index) => (
                                    <div className="each-fade" key={index}>
                                        <div className="image-container ">
                                            <img src={fadeImage.url} />
                                        </div>
                                        <h2>{fadeImage.caption}</h2>
                                    </div>
                                ))}
                            </Fade>
                        </div>

                        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                            <div class="portfolio-wrap">
                                <img
                                    src="assets/img/portfolio/portfolio-9.jpg"
                                    class="img-fluid"
                                    alt=""
                                />
                                <div class="portfolio-links">
                                    <iframe
                                        width="560"
                                        height="315"
                                        src="https://www.youtube.com/embed/T0CoDpIhBwU"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col-lg-4 col-md-6 portfolio-item">
                            <div className="">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/T0CoDpIhBwU"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        </div> */}

                    </div>

                    {/* <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

                    </div> */}
                </div>
            </section>

            {/* <!-- End Portfolio Section --> */}
        </main >
    )
}
