import React from 'react'
// import dataStore from "../data_store/data.json"
import { useGetData } from '../data_store/getData'

const Projects = () => {
    const { mainContent: dataStore, loading } = useGetData()
    return (!loading &&
        <main id="main">
            {/* <!-- ======= Projects ======= --> */}
            <section id="projects" class="resume section-bg">
                <div class="container">
                    <div class="section-title">
                        <h2>Projects</h2>
                        <div class="row">
                            <div data-aos="fade-up">
                                <div class="resume-item">
                                    {
                                        dataStore.projects.map(value => {
                                            return (<>
                                                <p>
                                                    <em>
                                                        <b>{value.name}</b>
                                                    </em>
                                                </p>
                                                {value.date ? <h5>{value.date}</h5> : ''}
                                                {value.certificate_url && <a href={value.certificate_url} target="_blank">[Certificate]</a>}
                                                {value.details.length !== 0 && <ul>
                                                    {value.details.map(value => {
                                                        return <li>{value}</li>
                                                    })}
                                                </ul>}
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Projects Section --> */}
        </main>
    )
}

export default Projects
