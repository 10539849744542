import React from 'react'
// import dataStore from "../data_store/data.json"
import { useGetData } from '../data_store/getData'

const CertificationsAndExtraCurricular = () => {
    const { mainContent: dataStore, loading } = useGetData()

    return (!loading &&
        <main id="main">
            {/* <!-- ======= Certifications & Extra-Curricular Section ======= --> */}
            <section id="certifications" class="resume section-bg">
                <div class="container">
                    <div class="section-title">
                        <h2>Certifications & Extra-Curricular</h2>
                        <div class="row">
                            <div data-aos="fade-up">
                                <div class="resume-item pb-0">
                                    <ul>
                                        {
                                            dataStore.certifications.map(value => {
                                                return (<li>
                                                    {value.name}
                                                    <h5>{value.date}</h5>
                                                    {value.certificate_url && <a href={value.certificate_url} target="_blank">[Certificate]</a>}
                                                </li>)
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End Certifications & Extra-Curricular Section --> */}
        </main>
    )
}

export default CertificationsAndExtraCurricular
